<template>
  <CSLayout :type="'notice'">
    <div>
      <NoticeItem
        v-for="(item, index) in notices"
        :key="index"
        :data="item" />

      <div class="empty" v-if="notices.length === 0">
      등록된 글이 없습니다.
    </div>
    </div>

    <div class="pagination-wrapper">
      <Pagination
        :currentPage="currentPage"
        :lastPage="lastPage"
        :onPage="onPage" />
    </div>
  </CSLayout>
</template>

<script>
import CSLayout from '@/components/CSLayout.vue';
import NoticeItem from '@/components/NoticeItem.vue';
import Pagination from '@/components/Pagination.vue';
import services from '@/services';

export default {
  name: 'NoticeList',
  components: {
    CSLayout,
    NoticeItem,
    Pagination,
  },
  data() {
    return {
      notices: [],
      currentPage: 1,
      lastPage: 1,
    }
  },
  mounted() {
    this.getNotices();
  },
  methods: {
    getNotices: async function() {
      const {data, success} = await services.noticeList(this.currentPage);
      if (success) {
        const {lastPage, data: noticeData} = data;
        this.lastPage = lastPage;
        this.notices = noticeData;
      }
    },
    onPage: function(value) {
      this.currentPage = value;
      this.getNotices();
    },
  },
}
</script>

<style lang="scss" scoped>
.pagination-wrapper {
  margin-top: 50px;
}
.empty {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: -0.7px;
  color: #353535;
  padding: 30px;
  text-align: center;
}
</style>
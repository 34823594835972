<template>
  <div class="notice-item w-full flex flex-row" v-on:click="goToDetail">
    <div
      class="image"
      :style="{
        backgroundImage: image ? `url(${image})` : '',
      }"
    />

    <div class="flex-1 content-wrapper flex-wrap">
      <p class="title">{{ data.title }}</p>
      <p class="date">{{ date }}</p>
      <div class="content w-full h-full flex flex-wrap">
        {{ content }}
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'NoticeItem',
  props: {
    data: Object,
  },
  computed: {
    date: function() {
      const { createdAt: createdAt } = this.data;
      return dayjs(createdAt).format('YYYY.MM.DD');
    },
    image: function() {
      const {hasThumbnail} = this.data;
      if (hasThumbnail) {
        const BASE_URL = (process.env.VUE_APP_IS_LOCAL_SERVER == 1 ? process.env.VUE_APP_API_BASEURL_LOCAL : process.env.VUE_APP_API_BASEURL_SERVER);
        const {path, hashName: hashName} = hasThumbnail;
        const url = `${BASE_URL}${path}${hashName}`;
        return url;
      }

      return null;
    },
    content() {
      const span = document.createElement('span');
      span.innerHTML = this.data.contents;
      const result = span.textContent || span.innerText;
      const d = result.trim()
      if (d.length > 150) {
        return d.slice(0, 150) + '...';
      }
      return d;
    },
  },
  methods: {
    goToDetail: function() {
      this.$router.push({ name: 'NoticeDetail', params: { id: this.data.id } });
    }
  },
}
</script>

<style scoped lang="scss">
.notice-item {
  padding-top: 33px;
  cursor: pointer;
  border-bottom: 1px solid rgba(193, 193, 193, 0.5);

  .image {
    margin-right: 43px;
    width: 268px;
    min-width: 268px;
    height: 217px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 23px;
  }

  .content-wrapper {
    padding-bottom: 57px;
  }

  .title {
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.66;
    letter-spacing: -1.25px;
    text-align: left;
    color: #353535;
    margin: 0;
  }

  .date {
    margin-top: 13px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -0.15px;
    text-align: left;
    color: #aaa;
  }

  .content {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.93;
    letter-spacing: -0.14px;
    text-align: left;
    color: #656565;
    overflow: hidden;
  }

  .content /deep/ {
    box-sizing: border-box;

    div {
      width: 100%;
      box-sizing: border-box;
    }
    
    p {
      box-sizing: border-box;
      word-break: break-all;
    }
  }
}

@media (max-width: 639px) {
  .notice-item {
    overflow: hidden;
    flex-direction: column;
    .image {
      max-width: 250px;
      width: 100%;
      margin: 0 auto 15px;
    }
    .title {
      font-size: 15px;
      line-height: 1.3;
    }
    .date {
      font-size: 12px;
      margin-top: 5px;
      line-height: 1.3;
    }
    .content {
      font-size: 12px;
    }
  }
}
</style>